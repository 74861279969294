// custom_sidebar_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["customSidebar"];

  initialize() {
    // Add event listener to close sidebar when clicking outside of sidebar-content
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  open() {
    this.customSidebarTarget.classList.add("open");
  }

  close() {
    this.customSidebarTarget.classList.remove("open");
  }

  handleClickOutside(event) {
    if (event.target.classList.contains('open')) {
      this.close();
    }
  }
}
