import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "listingId",
    "optionOne",
    "optionTwo",
    "selectedOption1",
    "selectedOption2",
    "variantStockId",
    "optionsContainer",
    "price", 
    "addToCartBtn",
    "whatsAppBtn"
  ];

  option1(event) {
    const value = event.target.getAttribute("data-value");
    this.optionOneTarget.value = value;
    this.selectedOption1Target.textContent = value;
    const elements = document.querySelectorAll(".option1.active");
    elements.forEach(e => e.classList.remove("active"));
    event.target.classList.add("active");
    this.updateVariantValues();
  }
  option2(event) {
    const value = event.target.getAttribute("data-value");
    this.optionTwoTarget.value = value;
    this.selectedOption2Target.textContent = value;
    const elements = document.querySelectorAll(".option2.active");
    elements.forEach(e => e.classList.remove("active"));
    event.target.classList.add("active");
    this.updateVariantValues();
  }

  // Method to retrieve and filter data values from elements within a div
  getDataValues() {
    const elements = this.optionsContainerTarget.querySelectorAll(".active");
    const values = Array.from(elements).map(element => element.getAttribute("data-value-id"));
    return values.filter(value => value !== null);
  }

  extractIdFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('variant');
    return id;
  }

  async retriveVariantInfo() {
    var combinations = this.getDataValues();
    var variantId = this.extractIdFromUrl();
    let listingId = this.listingIdTarget.value
    const response = await fetch('/listing_variant_stocks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ combinations: [combinations], variant_id: variantId, listing_id: listingId })
    });

    const data = await response.json();
    return data;
  }

  async updateVariantValues() {
    this.addToCartBtnTarget.disabled = true
    this.whatsAppBtnTarget.classList.add('disabled-link')
    let data = await this.retriveVariantInfo()
    let price = data[0].price
    let stockVariantId = data[0].id

    if (price != null) {
      this.priceTarget.textContent = this.formatNumberWithCommas(price) + ' KES'
    }

    if (stockVariantId != null) {
      this.variantStockIdTarget.value = stockVariantId
      const url = new URL(window.location.href);
      url.searchParams.set('variant', stockVariantId);
      history.pushState(null, '', url);
    }

    var combinations = this.getDataValues();
    if (combinations.length == 0) {
      const ids = data[0].variant_option_ids
      this.markOptionAsActive(ids)
    }
    this.addToCartBtnTarget.disabled = false
    this.whatsAppBtnTarget.classList.remove('disabled-link')
  }

  formatNumberWithCommas(number) {
    // Convert number to string and use regex to insert commas every three digits
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  markOptionAsActive(ids) {
    ids.forEach((id, index) => {
      // Find elements with the corresponding ID
      const elements = this.optionsContainerTarget.querySelectorAll(`#option-${id}`);
      // Add the class ".active" to each element found
      elements.forEach(element => {
        const value = element.dataset.value
        element.classList.add("active");
        if (index == 0) {
          this.optionOneTarget.value = value;
          this.selectedOption1Target.textContent = value;
        } else {
          this.optionTwoTarget.value = value;
          this.selectedOption2Target.textContent = value;
        }
      });
    });
  }

  forceSelectOptions() {
    const optionOnes = this.optionsContainerTarget.querySelectorAll('.option1')
    const optionTwos = this.optionsContainerTarget.querySelectorAll('.option2')

    if (optionOnes.length === 1) {
      // Programmatically trigger a click on the only option
      optionOnes[0].click();
    }
  
    // Check if there is only one option in optionTwos (if needed)
    if (optionTwos.length === 1) {
      // Programmatically trigger a click on the only option
      optionTwos[0].click();
    }
  }

  validateSelection(event) {
    const optionOneSelected = this.optionsContainerTarget.querySelector(".option1.active");
    const optionTwoSelected = this.optionsContainerTarget.querySelector(".option2.active");

    const optionOnes = this.optionsContainerTarget.querySelectorAll('.option1');
    const optionTwos = this.optionsContainerTarget.querySelectorAll('.option2');

    let isValid = true;

    if (optionOnes.length > 1 && !optionOneSelected) {
      isValid = false;
      const variantName = this.selectedOption1Target.getAttribute('data-variant-name');
      this.selectedOption1Target.innerHTML = `<span style='color: red'>Please select a ${variantName}</span>`;
    } else if (optionTwos.length > 1 && !optionTwoSelected) {
      isValid = false;
      const variantName = this.selectedOption2Target.getAttribute('data-variant-name');
      this.selectedOption2Target.innerHTML = `<span style='color: red'>Please select a ${variantName}</span>`;
    }

    if (!isValid) {
      event.preventDefault();
    } else {
      this.addToCart();
    }
  }

  addToCart() {
    const button = this.addToCartBtnTarget;
    button.value = "Adding...";
    
    setTimeout(() => {
      button.value = "Add to cart";
    }, 2000); // Adjust the delay as needed
  }

  connect() {
    let variantId = this.extractIdFromUrl();
    if (variantId) {
      this.updateVariantValues();
    }
    this.forceSelectOptions();
    this.addToCartBtnTarget.addEventListener("click", (event) => this.validateSelection(event));
    this.whatsAppBtnTarget.addEventListener("click", (event) => this.validateSelection(event));
  }
}
